<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
export default {
  page: {
    title: "รายละเอียดงานอนุมัติในสาขา",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "รายละเอียดงานอนุมัติในสาขา",
      items: [
        {
          text: "งานอนุมัติในสาขา",
          active: false,
          href: "/main-branch-approval",
        },
        {
          text: "รายละเอียดงานอนุมัติในสาขา",
          active: true,
        },
      ],
      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsProduct: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      optionBranch: [],
      rowsDetail: [],
      loading: false,
      userBranchAccessId: "",
      interiorColor: "",
      exteriorColor: "",
      branchApproveVehicleTransferDetail: [],
      formId: this.$route.params.formId,
      vehicle: [],
      userBranchId: "",
      stock: "",
      supId: "",
      transferCost: "",
      branchId: "",
      toBranchId: "",
      objective: "",
      model: "",
      licensePlate: "",
      vin: "",
      saleType: "",
      saleCondition: "",
      downPayment: "",
      premium: "",
      fuelPrice: "",
      departureDate: "",
      status: "",
      user:"",
      saleDate: "",
      nameState: null,
      apvCode: "",
      apvDate: "",
      note: "",
      cost: "",
      statusType: "",
      transferdId: "",
      optionStatus: [
        {
          nameTh: "อนุมัติ",
          status: "APV",
        },
        {
          nameTh: "ไม่อนุมัติ",
          status: "NAPV",
        },
      ],
      userBranch: "",
      prodId: "",
      filterOn: [],
      rowsPO: [],
      rowsSuppliers: [],
      sortBy: "age",
      sortDesc: false,
      submitform: false,
      overlayFlag: false,
    };
  },
  validations: {
    statusType: {
      required,
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsPO.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranchAccessId = user.branchId;
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsPO.total;

    this.getDataDetail();
  },
  created() {
    // this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    numberFormats(x) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(x);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    checksaleType(data) {
      let type;
      if (data == "N") {
        type = "มือใหม่";
      } else if (data == "C") {
        type = "รถมือสอง";
      }
      return type;
    },
    checksaleCondition(data) {
      let type;
      if (data == "C") {
        type = "เงินสด";
      } else if (data == "I") {
        type = "ผ่อนชำระ";
      } else if (data == "F") {
        type = "ไฟแนนซ์";
      }
      return type;
    },
    checksaleStatus(data) {
      let type;
      if (data == "TRVEXP") {
        type = "ขออนุมัติโอนรถระหว่างสาขา (ขอนุมัติการเดินทาง)";
      }
      return type;
    },
    formSubmit() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitDetail();
      }
    },
    getDataDetail: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/branch-approval/show", {
          params: {
            formId: atob(this.formId),
          },
        })
        .then((response) => {
          this.user = response.data.formData.userFullName;
          this.status = response.data.approval.formTypeId;
          this.branchId = response.data.formData.branchName;
          this.toBranchId = response.data.formData.toBranchName;
          this.departureDate = response.data.formData.departure_date;
          this.saleDate = response.data.formData.sale_date;
          this.objective = response.data.formData.objective;
          this.model = response.data.formData.vehicleModelNameTh;
          this.licensePlate = response.data.formData.licensePlate;
          this.vin = response.data.formData.vin;
          this.saleType = this.checksaleType(response.data.formData.sale_type);
          this.saleCondition = this.checksaleCondition(
            response.data.formData.sale_condition
          );
          this.downPayment = response.data.formData.down_payment;
          this.premium = response.data.formData.premium;
          this.fuelPrice = response.data.formData.fuel_price;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    submitDetail: function () {
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/branch-approval/submit", {
          formId: atob(this.formId),
          status: this.statusType != null ? this.statusType.status : undefined,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          //   this.showDetail();
          this.$router.push({ name: "main-branch-approval" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false; //skeleton true
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="alert alert-warning" role="alert">
              <div class="row">
                <div class="col-md-9">
                  <i class="uil-file-exclamation font-size-18"></i>
                  &nbsp;
                  <b>{{ checksaleStatus(this.status) }}</b>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <p class="card-title-desc">รายละเอียด</p>
              </div>
              <!-- Search -->
              <div class="row">
                <div class="col-12">
                  <div class="col-12 col-sm-12 col-md-12">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label>ผู้ขออนุมัติ :</label>
                          <input
                            disabled
                            class="form-control"
                            v-model="user"
                            placeholder=""
                            disabied
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label>สาขา :</label>
                          <input
                            disabled
                            class="form-control"
                            v-model="branchId"
                            placeholder="สาขา"
                            disabied
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="">ไปที่สาขา :</label>
                          <input
                           disabled
                            class="form-control"
                            v-model="toBranchId"
                            placeholder="สาขา"
                            disabied
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="validation">วันเดินทาง :</label>
                          <date-picker
                            disabled
                            v-model="departureDate"
                            format="YYYY-MM-DD"
                            value-type="format"
                          ></date-picker>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="validation">วันที่ขาย :</label>
                          <date-picker
                            disabled
                            v-model="saleDate"
                            format="YYYY-MM-DD"
                            value-type="format"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3 position-relative">
                          <label for="">จุดประสงค์ :</label>
                          <textarea
                            disabled
                            v-model="objective"
                            class="form-control"
                            name="textarea"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="">รุ่น :</label>
                          <input
                            disabled
                            class="form-control"
                            v-model="model"
                            placeholder="รุ่น"
                            disabied
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="">ทะเบียนรถ :</label>
                          <input
                            disabled
                            class="form-control"
                            v-model="licensePlate"
                            type="text"
                            placeholder="ทะเบียนรถ"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="">เลขตัวถัง :</label>
                          <input
                            disabled
                            class="form-control"
                            v-model="vin"
                            type="text"
                            placeholder="เลขตัวถัง"
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for=""> ประเภทรถ :</label>
                          <input
                            disabled
                            class="form-control"
                            v-model="saleType"
                            type="text"
                            placeholder="ประเภทรถ"
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="">ประเภทการซื้อ :</label>
                          <input
                            disabled
                            class="form-control"
                            v-model="saleCondition"
                            type="text"
                            placeholder="ประเภทการซื้อ"
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="">เงินดาวน์ :</label>
                          <input
                            disabled
                            class="form-control"
                            v-model="downPayment"
                            type="text"
                            placeholder="เงินดาวน์"
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="">ของแถม :</label>
                          <input
                            disabled
                            class="form-control"
                            v-model="premium"
                            type="text"
                            placeholder="ของแถม"
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="">ค่าน้ำมัน :</label>
                          <input
                            disabled
                            class="form-control"
                            v-model="fuelPrice"
                            type="text"
                          />
                        </div>
                      </div>
                      <hr />

                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                          <div class="row">
                            <div class="col-md-8 col-12"></div>
                            <div class="col-md-2 col-8">
                              <div class="mb-3 position-relative">
                                <code> * </code>สถานะ:
                                <multiselect
                                  v-model="statusType"
                                  label="nameTh"
                                  type="search"
                                  :options="optionStatus"
                                  :show-labels="false"
                                  open-direction="bottom"
                                  placeholder=""
                                  :class="{
                                    'is-invalid':
                                      submitform && $v.statusType.$error,
                                  }"
                                >
                                  <span slot="noResult">ไม่พบข้อมูล</span>
                                </multiselect>
                                <div
                                  v-if="submitform && $v.statusType.$error"
                                  class="invalid-tooltip"
                                >
                                  <span v-if="!$v.statusType.required">{{
                                    error
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 col-4 mt-3">
                              <b-button
                                class="btn float-end m-1"
                                variant="success"
                                @click="formSubmit"
                              >
                                บันทึก
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
